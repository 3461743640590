import './src/styles/global.css'
import 'typeface-poppins'
import { config } from '@fortawesome/fontawesome-svg-core'
import { WrapRootElementNodeArgs } from 'gatsby'
import React, { ReactNode } from 'react'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { createRoot } from 'react-dom/client'
import PageWrapper from './src/components/PageWrapper'
import Root from './src/components/Root'

config.autoAddCss = false

export const replaceHydrateFunction = () => (element, container) => {
  const root = createRoot(container)
  root.render(element)
}

export const wrapRootElement = ({ element }: WrapRootElementNodeArgs): ReactNode => <Root>{element}</Root>

export const wrapPageElement = ({ element }) => <PageWrapper>{element}</PageWrapper>

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.state && routerProps.location.state.noScroll) {
    return false
  }
  return true
}
