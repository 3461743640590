import { graphql, useStaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { CookieConsentQuery } from '../../generated/graphql-types'
import Container from '../Container'
import Selection from '../Selection'

const initGA = () => {
  const id = process.env.GATSBY_GOOGLE_ANALYTICS_ID
  if (id) {
    ReactGA.initialize(id)
  }
}

const CookieConsent: React.FC = () => {
  const cookieConsets = useStaticQuery<CookieConsentQuery>(graphql`
    query CookieConsent {
      allContentfulCookieConsent {
        edges {
          node {
            description
            node_locale
            title
          }
        }
      }
    }
  `)

  const cookieConsent = cookieConsets.allContentfulCookieConsent.edges.find(({ node }) => node.node_locale === 'fi')
  if (!cookieConsent) {
    return null
  }

  const [isVisible, setIsVisible] = useState(false)
  const [shouldRender, setShouldRender] = useState(!Cookies.get('user-consent'))
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(Cookies.get('analytics-cookies') === 'enabled')

  useEffect(() => {
    const consent = Cookies.get('user-consent')
    if (!consent) {
      setIsVisible(true)
    }
  }, [Cookies])

  useEffect(() => {
    if (isAnalyticsEnabled) {
      initGA()
    }
  }, [isAnalyticsEnabled])

  const acceptAllCookies = () => {
    Cookies.set('user-consent', 'all', { expires: 365 })
    Cookies.set('analytics-cookies', 'enabled', { expires: 365 })
    setIsAnalyticsEnabled(true)
    setIsVisible(false)
    setTimeout(() => {
      setShouldRender(false)
    }, 1000)
  }

  const acceptNecessaryCookies = () => {
    Cookies.set('user-consent', 'necessary', { expires: 365 })
    setIsAnalyticsEnabled(false)
    Cookies.remove('analytics-cookies')
    setIsVisible(false)
    setTimeout(() => {
      setShouldRender(false)
    }, 1000)
  }

  const transitionStyle = `transition-transform duration-300 ${isVisible ? 'translate-y-0' : 'translate-y-0'}`

  if (!isVisible && !shouldRender) {
    return null
  }

  const { title, description } = cookieConsent.node
  return (
    <div
      className={`fixed inset-x-0 bottom-0 p-4 bg-gray-800 border-t-[1px] border-stone-800 shadow-lg text-white ${transitionStyle} z-10`}
    >
      <Container noPadding>
        <div className="w-full flex flex-col lg:flex-row">
          <div className="w-full lg:w-8/12">
            <p className="font-bold">{title}</p>
            <p className="text-xs lg:text-base">{description}</p>
          </div>

          <div className="w-full lg:w-6/12 flex flex-wrap justify-end items-center pb-0 lg:pb-5 pt-5">
            <Selection dark className="text-xs lg:text-base my-1 ml-3 px-4 h-10 whitespace-nowrap" small>
              <button onClick={acceptNecessaryCookies}>Vain välttämättömät</button>
            </Selection>
            <Selection dark className="text-xs lg:text-base my-1 ml-3 px-4 h-10 whitespace-nowrap" small>
              <button onClick={acceptAllCookies}>Hyväksy kaikki</button>
            </Selection>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CookieConsent
