import React from 'react'
import { Helmet } from 'react-helmet'
import { NavigationProvider } from '../../context/NavigationContext'
import CookieConsent from '../CookieConsent'

interface RootProps {
  children?: React.ReactNode
}

const Root: React.FC<RootProps> = ({ children }) => (
  <NavigationProvider>
    <Helmet>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=0"
      />
    </Helmet>
    <div>{children}</div>
    <CookieConsent />
  </NavigationProvider>
)

export default Root
